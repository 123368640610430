@font-face {
  font-family: alegreyaMedium;
  src: url(../fonts/AlegreyaSansSC-Medium.ttf); }

@font-face {
  font-family: alegreyaMediumItalic;
  src: url(../fonts/AlegreyaSansSC-MediumItalic.ttf); }

@font-face {
  font-family: alegreyaBold;
  src: url(../fonts/AlegreyaSansSC-Bold.ttf); }

@keyframes spinning {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes opacity {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  font-family: alegreyaMedium;
  font-size: 13px;
  text-transform: lowercase;
  width: 100vw;
  background-color: #fff;
  overflow-x: hidden; }

a {
  color: #0d8bbe;
  text-decoration: none;
  display: inline-block; }
  a::after {
    content: "";
    height: 1px;
    width: 0;
    display: block;
    transition: width 0.5s;
    background-color: #0d8bbe; }
  a:hover::after {
    width: 100%;
    transition: width 0.5s; }
  a.noUnderline::after {
    display: none; }

p {
  padding: 0;
  margin: 0; }

header {
  opacity: 0;
  animation: opacity;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  width: 100%;
  height: 70px;
  background-color: #fff;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 200; }
  header .header-logo,
  header .header-logoLink,
  header .noUnderline {
    display: block;
    height: 80%; }
  header .header-logo {
    padding-left: 20px; }
  header .logo {
    width: auto;
    height: 100%; }
  header .header-menu {
    position: relative;
    text-align: right;
    padding-right: 20px; }
    header .header-menu .burger {
      width: 28px;
      display: inline-block;
      border: 0;
      background: none;
      outline: 0;
      padding: 0;
      cursor: pointer;
      border-bottom: 2px solid #771ed2;
      transition: border-bottom 1s ease-in-out;
      position: relative;
      z-index: 10; }
      header .header-menu .burger::-moz-focus-inner {
        border: 0;
        padding: 0; }
      header .header-menu .burger:before {
        content: "";
        display: block;
        border-bottom: 2px solid #0d8bbe;
        width: 100%;
        margin-bottom: 5px;
        transition: transform 0.5s ease-in-out; }
      header .header-menu .burger:after {
        content: "";
        display: block;
        border-bottom: 2px solid #f26e23;
        width: 100%;
        margin-bottom: 5px;
        transition: transform 0.5s ease-in-out; }
    header .header-menu .burger-check {
      display: none; }
    header .header-menu .burger-check:checked ~ .burger {
      border-bottom: 2px solid currentColor;
      transition: border-bottom 0.8s ease-in-out; }
      header .header-menu .burger-check:checked ~ .burger:before {
        transform: rotate(405deg) translate(-1px, -1px);
        transition: transform 0.5s ease-in-out; }
      header .header-menu .burger-check:checked ~ .burger:after {
        transform: rotate(-405deg) translate(6px, -6px);
        transition: transform 0.5s ease-in-out; }
    header .header-menu .navigation {
      overflow: hidden;
      max-height: 0;
      position: absolute;
      top: -20px;
      right: 13px;
      text-align: left;
      opacity: 0;
      transition: max-height 0.5s ease-in-out; }
    header .header-menu .burger-check:checked ~ .navigation {
      max-height: 500px;
      opacity: 1;
      transition: max-height 0.5s ease-in-out; }
    header .header-menu .menu {
      width: 250px;
      margin: 0;
      padding: 15px 20px;
      background: #fff;
      border: 1px solid #0d8bbe;
      list-style-type: none; }
    header .header-menu .menu-item {
      list-style: none;
      font-size: 25px;
      padding: 5px 0;
      padding: 10px 0;
      border-bottom: 1px solid #cfcece; }
      header .header-menu .menu-item:last-child {
        border-bottom: 0; }
    header .header-menu .nav-button {
      text-transform: uppercase;
      display: inline-block;
      color: #0d8bbe;
      transition: color 0.3s ease;
      font-weight: 700;
      letter-spacing: 3px;
      font-size: 17px; }
      header .header-menu .nav-button:hover {
        color: #38baee; }
      header .header-menu .nav-button::after {
        display: none; }
  @media (min-width: 1024px) {
    header .header-menu .burger,
    header .header-menu .burger-check {
      display: none; }
    header .header-menu .navigation {
      position: static;
      top: 0;
      right: 0;
      text-align: right;
      height: 100%;
      max-height: 100%;
      opacity: 1; }
    header .header-menu .menu {
      width: auto;
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;
      padding: 0; }
      header .header-menu .menu .menu-item {
        padding: 0 20px;
        border-bottom: 0; }
    header .header-menu .nav-button:hover {
      color: #0d8bbe; } }
  @media (min-width: 1025px) {
    header .header-menu .nav-button:hover {
      color: #0d8bbe; }
    header .header-menu .nav-button::after {
      display: block;
      background-color: #0d8bbe; } }

footer {
  opacity: 0;
  animation: opacity;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  text-align: center;
  padding: 20px 0;
  background: #0d8bbe;
  color: #fff;
  border-top: 1px solid #fda915; }
  footer a {
    color: #fff; }
    footer a::after {
      background-color: #fff; }

.main-section.main-banner .banner-logoWrapper {
  position: relative; }

.main-section.main-banner .banner-logo {
  width: 80%; }
  .main-section.main-banner .banner-logo.banner-c {
    animation: spinning;
    animation-duration: 1.5s; }
  .main-section.main-banner .banner-logo.banner-crumbs {
    opacity: 0;
    animation: opacity;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%); }

.main-section.main-banner h1 {
  font-family: alegreyaBold;
  opacity: 0;
  animation: opacity;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  font-size: 40px; }

@media (min-width: 768px) {
  .main-section.main-banner {
    padding-bottom: 70px; }
    .main-section.main-banner .banner-logo {
      width: auto;
      max-width: 270px; }
    .main-section.main-banner h1 {
      font-size: 40px; } }

.main-section.main-communities {
  justify-content: center; }
  .main-section.main-communities .community-logo {
    height: 150px;
    align-self: stretch;
    margin: 10px; }
    .main-section.main-communities .community-logo .community-link {
      position: relative;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      transform: rotateY(0);
      transition: transform 0.5s; }
      .main-section.main-communities .community-logo .community-link .community-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        font-size: 30px;
        color: #fff;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .main-section.main-communities .community-logo .community-link img {
        max-width: 200px;
        opacity: 1; }
      .main-section.main-communities .community-logo .community-link.codingGym img {
        width: 75%; }
  @media (min-width: 1025px) {
    .main-section.main-communities .community-logo:hover .community-link {
      transform: rotateY(180deg);
      transition: transform 0.5s; }
      .main-section.main-communities .community-logo:hover .community-link.codingGym {
        background-color: #47c1c7; }
      .main-section.main-communities .community-logo:hover .community-link.cpp {
        background-color: #2945a4; }
      .main-section.main-communities .community-logo:hover .community-link.pip {
        background-color: #007dfb; }
      .main-section.main-communities .community-logo:hover .community-link.tgs {
        background-color: #903034; }
      .main-section.main-communities .community-logo:hover .community-link .community-text {
        opacity: 1;
        transform: rotateY(180deg); }
      .main-section.main-communities .community-logo:hover .community-link img {
        opacity: 0; } }

.main-section.main-contacts {
  font-size: 17px; }
  @media (min-width: 768px) {
    .main-section.main-contacts {
      font-size: 22px; } }

main {
  font-size: 20px;
  line-height: 30px; }
  @media (min-width: 768px) {
    main {
      font-size: 22px; } }

.main-section {
  width: 100%;
  padding: 10px 20px 30px;
  text-align: center;
  max-width: 1600px;
  margin: 0 auto; }
  .main-section:not(.main-banner) {
    opacity: 0;
    animation: opacity;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards; }

.section-title {
  font-size: 30px;
  color: #000;
  margin-bottom: 20px;
  display: inline-block; }

.section-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 0 auto; }
  .section-content.no-flex {
    display: block; }
  .section-content .info-text {
    margin-bottom: 10px; }
